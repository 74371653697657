<script setup>
import { ref } from 'vue';

const topGallery = ref({
    index: 0,
    count: 3,
    items: [
        {
            key: 0,
            img: 'storage/gallery/tecnocasa_insieme.webp',
            title: 'Tecnocasa',
        },
        {
            key: 1,
            img: 'storage/gallery/tecnorete_insieme.webp',
            title: 'Tecnorete',
        },
        {
            key: 2,
            img: 'storage/gallery/kiron_insieme.webp',
            title: 'Kiron',
        },
    ]

});
</script>
<template>

    <Head title="Home" />
    <div class="w-full text-center">
        <div class="gallery-top bg-white rounded-3xl my-3">
            <div v-for="item in topGallery.items" :key="item.key" class="gallery_item"
                :class="{ 'active': item.key == topGallery.index }"><img :src="item.img" :alt="item.title" /></div>
            <!-- <div class="arrow arrow-left">&lt;</div> -->
            <!-- <div class="arrow arrow-right">&gt;</div> -->
        </div>
    </div>
    <div class="description">
        <div class="flex flex-wrap justify-center rounded-3xl bg-[#292929] text-white text-center">
            <div class="basis-3/4 pt-4 pb-1">
                <h2 class="mb-0 text-4xl">CUOIO <span class="text-[#d6b161]">RIGENERATO</span></h2>
                <p class="text-xl mb-3">100% Materiale riciclato</p>
                <p>Il rigenerato di cuoio, sinonimo di impegno ambientale, nasce dal riciclo degli scarti di produzione
                    della pelle lavorata nei settori:<br>pelletteria – calzature – arredo – concerie.<br>Questo
                    materiale con la sua morbidezza, elaticità e piacevolezza al tatto, è il miglior sostituto naturale
                    della pelle.<br>Le sue caratteristiche tecniche permettono di realizzare prodotti finiti con elevati
                    standard qualitativi.</p>
                
            </div>
            <div class="basis-3/4 pb-4">
                <a href="https://prodottialfa.eu/" target="_blank"><span class="text-[#d6b161]">Scopri di
                    più</span></a>
                <img class="w-1/4 m-auto" src="../../images/logoprodottialfa.png" alt="Tecnocasa" />
            </div>
        </div>

        <div class="flex flex-wrap my-4 ">
            <a href="/order" class="primary-btn text-3xl rounded-3xl cursor-pointer">CLICCA QUI PER ORDINARE I TUOI
                GADGETS</a>
        </div>

        <div class="flex flex-wrap mt-4 pb-4 rounded-3xl bg-white">
            <div class="basis-1/4 p-4 pt-8">
                <img :src="'storage/showcase/tecnocasa_cartellina.webp'" alt="Tecnocasa" />
            </div>
            <div class="basis-3/4 p-4">
                <h3 class="text-green-700">Cartellina Portadocumenti</h3>
                <p>La cartelletta a logo TECNOCASA con il Vs. indirizzo che conferisce un tocco di professionalità,
                    riteniamo sia la perfetta fusione di eleganza, sostenibilità e praticità, riconoscibile a distanza.
                </p>
            </div>
        </div>

        <div class="flex flex-wrap mt-4 pb-4 rounded-3xl bg-white">
            <div class="basis-3/4 p-4">
                <h3 class="text-green-700">Sottobicchiere</h3>
                <p>Un'idea simpatica e funzionale, un efficace strumento di branding che esce dal Vs. Studio. Valido per
                    uffici, sale riunioni, ristoranti, pizzerie, bar, caffetterie... .</p>
            </div>
            <div class="basis-1/4 p-4 pt-8">
                <img :src="'storage/showcase/tecnocasa_sottobicchiere.webp'" class="rounded-3xl" alt="Tecnocasa" />
            </div>
        </div>

        <div class="flex flex-wrap mt-4 pb-4 rounded-3xl bg-white">
            <div class="basis-1/4 p-4 pt-8">
                <img :src="'storage/showcase/tecnocasa_portachiavi.webp'" alt="Tecnocasa" />
            </div>
            <div class="basis-3/4 p-4">
                <h3 class="text-green-700">Portachiavi</h3>
                <p>Il portachiavi con il robusto anello a moschettone, facile e comodo da usare, combina estetica e
                    praticità in un design altamente identificativo.</p>
            </div>
        </div>

        <div class="flex flex-wrap my-4 ">
            <a href="/order" class="primary-btn text-3xl rounded-3xl cursor-pointer">CLICCA QUI PER ORDINARE I TUOI
                GADGETS</a>
        </div>

        <div class="flex flex-wrap mt-4 pb-4 rounded-3xl">
            <div class="basis-1/6 p-2">
                <img :src="'storage/showcase/tecnocasa_per_001.webp'" class="rounded-xl" alt="Tecnocasa" />
            </div>
            <div class="basis-1/6 p-2">
                <img :src="'storage/showcase/tecnocasa_per_002.webp'" class="rounded-xl" alt="Tecnocasa" />
            </div>
            <div class="basis-1/6 p-2">
                <img :src="'storage/showcase/tecnocasa_per_003.webp'" class="rounded-xl" alt="Tecnocasa" />
            </div>
            <div class="basis-1/6 p-2">
                <img :src="'storage/showcase/tecnocasa_per_004.webp'" class="rounded-xl" alt="Tecnocasa" />
            </div>
            <div class="basis-1/6 p-2">
                <img :src="'storage/showcase/tecnocasa_per_005.webp'" class="rounded-xl" alt="Tecnocasa" />
            </div>
            <div class="basis-1/6 p-2">
                <img :src="'storage/showcase/tecnocasa_per_006.webp'" class="rounded-xl" alt="Tecnocasa" />
            </div>
        </div>
    </div>

</template>