<script setup>
import { useForm } from "@inertiajs/vue3";
import { onBeforeMount, ref, watch } from "vue";
import TextInput from "../../Components/TextInput.vue";
import SelectInputFlex from "../../Components/SelectInputFlex.vue";

const props = defineProps({
    branchesType: Array
});

const branchesType = ref(null);
const brancheTypeZero = ref(false);

const form = useForm({
    email: null,
    password: null,
    password_confirmation: null,
    avatar: null,
    preview: null,
    name: null,
    surname: null,
    phone: null,
    type_id: null,
});

const handleSubmit = () => {
    form.post('/register', {
        onError: () => form.reset('passwrord', 'password_confirmation'),
    });
}

const handleFileUpload = (e) => {
    form.avatar = e.target.files[0];
    form.preview = URL.createObjectURL(e.target.files[0]);
}

onBeforeMount(() => {
    branchesType.value = [
        { label: 'Scegli un tipo', value: null, selected: 'selected', disabled: true, hidden: true },
        ...props.branchesType.map((branchType) => ({ label: branchType.name, value: branchType.id })),
        //{ label: 'Altro', value: '0' },
    ];
});

watch(() => form.azienda_tipo, () => {
    if (form.azienda_tipo === '0'){ brancheTypeZero.value = true; return; }
    brancheTypeZero.value = false;
    //form.azienda_tipo_string = null; 
});

</script>
<template>
    <Head title="Home" />
    <h1 class="title">Register a new account</h1>
    <div class="w-2/4 mx-auto">
        <form @submit.prevent="handleSubmit">
            <!-- Upload image 
            <div class="grid place-items-center">
                
                <div class="relative w-28 h-28 rounded-full overflow-hidden border border-slate-300">
                    <label for="avatar" class="absolute inset-0 grid content-end cursor-pointer">
                        <span class="bg-white/70 pb-2 text-center">Avatar</span>
                    </label>
                    <input type="file" id="avatar" @input="handleFileUpload" hidden />
                    <img class="object-cover w-28 h-28" :src="form.preview ?? 'storage/avatar/default.png'" />
                </div>
                <p>{{ form.errors.avatar }}</p>
            </div>
            -->
            <TextInput v-model="form.name" name="Nome" type="text" :message="form.errors.name" />
            <TextInput v-model="form.surname" name="Cognome" type="text" :message="form.errors.surname" />
            <TextInput v-model="form.phone" name="Telefono" type="text" :message="form.errors.phone" />
            <SelectInputFlex 
                cls="mb-6"
                v-model:selected="form.type_id" 
                v-model:options="branchesType"
                name="Tipo" 
                :message="form.errors.type_id" 
                :required="true"
                basisDivide="0" />
            <TextInput v-model="form.email" name="Email" type="email" :message="form.errors.email" />
            <TextInput v-model="form.password" name="Password" type="password" :message="form.errors.password" />
            <TextInput v-model="form.password_confirmation" name="Conferma Password" type="password" />
            

            <div class="text-slate-500 mb-2">
                <p>Hai gia un account?<Link href="/login" class="text-link" > Login </Link></p>
                <button class="primary-btn mt-2" :disabled="form.processing">REGISTRATI</button>
            </div>
        </form>
    </div>
</template>