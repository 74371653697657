<script setup></script>
<template>
    <div class="w-full bg-[#3C3C3D] print:hidden">
        <header>
            <nav>
                <div class="space-x-6" >
                    <a href="/"><img src="../../images/prodottialfafiniti_logo.svg" class="logo h-8" /></a>
                </div>
                <div v-if="$page.props.auth.user" class="space-x-6 flex" >
                    <Link :href="$page.props.auth.user.role == 'admin' ? '/admin/dashboard' : '/user/dashboard'" class="nav-link" :class="{'bg-slate-700' : $page.component == 'Dashboard'}">Dashboard</Link>
                    <Link :href="'/logout'" method="post" as="button" type="button" class="nav-link">Logout {{ $page.props.auth.user.name }}</Link>
                </div>
                <div v-else class="space-x-6" >
                    <!-- <Link :href="'/login'" class="nav-link">Login</Link> -->
                    <!-- <Link :href="'/register'" class="nav-link">Register</Link> -->
                </div>
            </nav>
        </header>
    </div>

    <main class="container mx-auto min-h-screen">
        <slot />
    </main>

    <footer class="bg-[#3C3C3D] text-white absolute w-full mt-2 py-2 print:hidde text-center">
        
        <p class="text-center text-xs">© Copyright brno – P.IVA brno REA: XXX – CAP. SOCIALE 750.000 EURO</p>
    </footer>
    
</template>