<script setup>
const props = defineProps({
    indirizzo: String,
    telefono: String
})

console.log(props);

import { ref, onMounted, onUnmounted } from 'vue';

const containerCartellina = ref(null);

const resizeIndirizzoCartellina = (entries) => {
    for (let entry of entries) {
        const containerElement = entry.target;
        const content = containerElement.querySelector('#cartellina-indirizzo');
        const containerWidth = containerElement.clientWidth;
        const containerHeight = containerElement.clientHeight;
        const scaleFactor = Math.min(containerWidth / 1371, containerHeight / 914);
        content.style.fontSize = `${1.1 * scaleFactor}rem`;
    }
}

let resizeObserver;

onMounted(() => {
    resizeObserver = new ResizeObserver(resizeIndirizzoCartellina);
    if (containerCartellina.value) {
        resizeObserver.observe(containerCartellina.value);
    }
});

onUnmounted(() => {
    if (resizeObserver && containerCartellina.value) {
        resizeObserver.unobserve(containerCartellina.value);
    }
});


</script>
<template>
    <div ref="containerCartellina" class="svg-anteprima w-full bg-white aspect-[1371/914] relative">
        <svg width="100%" height="100%" viewBox="0 0 1376 919" version="1.1" xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/"
            style="fill-rule:evenodd;clip-rule:evenodd;stroke-miterlimit:3.864;">
            <g transform="matrix(0.364448,0,0,0.364448,37.7061,-2269.42)">
                <g transform="matrix(-7.65404e-16,4.16667,-4.16667,-7.65404e-16,4582.7,-4147.65)">
                    <g transform="matrix(1,0,0,1,2830.41,551.539)">
                        <path
                            d="M0,-4.537L-5.093,-2.262L0,0.014L0,-4.537ZM0.812,-4.903L0.812,0.379L3.345,1.517L3.345,2.56L-6.135,-1.774L-6.135,-2.763L3.345,-7.097L3.345,-6.04L0.812,-4.903Z"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2825.15,537.372)">
                        <path
                            d="M0,4.497L3.724,4.497L3.724,-0.406L4.591,-0.406L4.591,4.497L8.614,4.497L8.614,5.499L-0.867,5.499L-0.867,-1.002L0,-1.002L0,4.497Z"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2825.15,528.799)">
                        <path
                            d="M0,4.497L3.724,4.497L3.724,-0.406L4.591,-0.406L4.591,4.497L8.614,4.497L8.614,5.499L-0.867,5.499L-0.867,-1.002L0,-1.002L0,4.497Z"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,0,-1784.2)">
                        <rect x="2824.28" y="2308.93" width="9.481" height="1.002" style="fill:rgb(0,152,74);" />
                    </g>
                    <g transform="matrix(1,0,0,1,2824.28,515.324)">
                        <path d="M0,6.311L0,5.309L8.614,5.309L8.614,0L9.481,0L9.481,6.311L0,6.311Z"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,0,-1808.34)">
                        <rect x="2824.28" y="2320.99" width="9.481" height="1.002" style="fill:rgb(0,152,74);" />
                    </g>
                    <g transform="matrix(1,0,0,1,2830.41,508.525)">
                        <path
                            d="M0,-4.537L-5.093,-2.262L0,0.014L0,-4.537ZM0.812,-4.903L0.812,0.379L3.345,1.517L3.345,2.56L-6.135,-1.774L-6.135,-2.763L3.345,-7.097L3.345,-6.04L0.812,-4.903Z"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2825.15,497.473)">
                        <path
                            d="M0,1.002L0,4.334L-0.867,4.334L-0.867,-3.332L0,-3.332L0,-0L8.614,-0L8.614,1.002L0,1.002Z"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2832.44,490.58)">
                        <path
                            d="M0,-4.077C-0.339,-4.682 -0.808,-5.156 -1.408,-5.499C-2.009,-5.842 -2.679,-6.014 -3.42,-6.014C-4.16,-6.014 -4.83,-5.842 -5.431,-5.499C-6.031,-5.156 -6.501,-4.682 -6.839,-4.077C-7.178,-3.472 -7.347,-2.795 -7.347,-2.046C-7.347,-1.296 -7.178,-0.617 -6.839,-0.007C-6.501,0.602 -6.031,1.081 -5.431,1.428C-4.83,1.776 -4.16,1.95 -3.42,1.95C-2.679,1.95 -2.009,1.776 -1.408,1.428C-0.808,1.081 -0.339,0.602 0,-0.007C0.339,-0.617 0.508,-1.296 0.508,-2.046C0.508,-2.795 0.339,-3.472 0,-4.077M0.772,0.507C0.352,1.27 -0.224,1.867 -0.955,2.295C-1.686,2.724 -2.508,2.939 -3.42,2.939C-4.332,2.939 -5.153,2.724 -5.885,2.295C-6.616,1.867 -7.192,1.27 -7.612,0.507C-8.031,-0.255 -8.241,-1.106 -8.241,-2.046C-8.241,-2.984 -8.033,-3.831 -7.618,-4.585C-7.203,-5.339 -6.627,-5.932 -5.891,-6.366C-5.156,-6.8 -4.332,-7.016 -3.42,-7.016C-2.508,-7.016 -1.684,-6.8 -0.948,-6.366C-0.212,-5.932 0.364,-5.339 0.779,-4.585C1.194,-3.831 1.402,-2.984 1.402,-2.046C1.402,-1.106 1.192,-0.255 0.772,0.507"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2757.68,474.295)">
                        <path
                            d="M0,7.821C-2.225,7.821 -3.948,6.099 -3.948,3.946C-3.948,1.794 -2.225,0 0,0C2.152,0 3.875,1.794 3.875,3.946C3.875,6.099 2.152,7.821 0,7.821Z"
                            style="fill:none;fill-rule:nonzero;stroke:rgb(0,152,74);stroke-width:2.09px;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2757.3,478.156)">
                        <path
                            d="M0,-0.275C0,-0.756 -0.185,-0.991 -0.671,-0.991C-0.932,-0.991 -1.315,-0.887 -1.315,-0.345L-1.315,0.809L0,0.809L0,-0.275ZM-2.165,1.716L-2.165,-0.523C-2.165,-1.701 -1.198,-1.917 -0.773,-1.917C-0.233,-1.917 0.233,-1.669 0.397,-1.231C0.575,-1.596 0.727,-1.794 1.644,-1.794C2.363,-1.794 2.541,-1.794 2.623,-1.991L2.753,-1.991L2.753,-0.967C2.52,-0.906 2.268,-0.868 1.761,-0.868C1.089,-0.868 0.829,-0.831 0.829,-0.147L0.829,0.809L2.753,0.809L2.753,1.716L-2.165,1.716Z"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(0,-1,-1,0,2808.31,780.696)">
                        <path
                            d="M-30.242,-30.242C-13.54,-30.242 0,-16.702 0,0C0,16.702 -13.54,30.242 -30.242,30.242C-46.944,30.242 -60.484,16.702 -60.484,0C-60.484,-16.702 -46.944,-30.242 -30.242,-30.242"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(0.711366,0.702821,0.702821,-0.711366,2723.25,778.296)">
                        <path d="M25.248,-20.975L50.526,-20.975L51.221,61.329L26.178,61.857L25.248,-20.975Z"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(0.709173,0.705035,0.705035,-0.709173,2751.97,790.106)">
                        <path d="M-3.378,-32.766L79.271,-32.766L78.967,-7.719L-2.7,-7.677L-3.378,-32.766Z"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2791.71,679.255)">
                        <path
                            d="M0,-19.867C4.386,-19.528 8.036,-19.203 12.007,-17.95C14.012,-17.316 16.51,-15.707 17.749,-13.854C19.688,-10.958 19.479,-6.704 18.247,-4.028C15.611,1.698 5.71,2.526 -0.155,3.295C-7.62,4.274 -15.686,4.682 -21.428,4.335C-25.074,4.115 -29.172,3.295 -32.043,2.342C-33.752,1.774 -35.755,0.836 -36.959,-0.778C-38.09,-2.295 -38.496,-4.943 -38.468,-7.062C-38.425,-10.398 -38.22,-15.946 -35.969,-19.77C-33.156,-24.549 -25.868,-21.388 -22.367,-17.594C-20.536,-15.61 -18.351,-13.045 -16.891,-11.005C-15.838,-9.532 -14.974,-8.163 -15.335,-7.289C-16.02,-5.631 -17.62,-5.933 -19.152,-6.737C-21.632,-8.037 -25.451,-13.555 -29.33,-10.41C-30.786,-9.18 -31.402,-6.887 -30.747,-5.4C-29.912,-3.547 -26.59,-2.56 -24.343,-2.165C-18.426,-1.125 -14.027,-1.062 -8.241,-1.93C-3.491,-2.641 0.767,-4.503 2.61,-7.094C3.67,-8.584 3.715,-10.765 2.633,-11.821C0.585,-13.822 -2.591,-14.407 -5.318,-13.916C-7.731,-13.481 -11.013,-13.574 -10.355,-16.564C-10.283,-16.89 -10.147,-17.196 -9.932,-17.453C-9.158,-18.382 -6.721,-20.387 0,-19.867"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2791.71,591.371)">
                        <path
                            d="M0,-19.867C4.386,-19.528 8.036,-19.203 12.007,-17.95C14.012,-17.316 16.51,-15.707 17.749,-13.854C19.688,-10.958 19.479,-6.704 18.247,-4.028C15.611,1.698 5.71,2.527 -0.155,3.295C-7.62,4.274 -15.686,4.682 -21.428,4.335C-25.074,4.115 -29.172,3.295 -32.043,2.342C-33.752,1.774 -35.755,0.836 -36.959,-0.778C-38.09,-2.295 -38.496,-4.943 -38.468,-7.062C-38.425,-10.398 -38.22,-15.946 -35.969,-19.77C-33.156,-24.549 -25.868,-21.388 -22.367,-17.594C-20.536,-15.61 -18.351,-13.045 -16.891,-11.005C-15.838,-9.532 -14.974,-8.163 -15.335,-7.289C-16.02,-5.631 -17.62,-5.933 -19.152,-6.736C-21.632,-8.036 -25.451,-13.555 -29.33,-10.41C-30.786,-9.18 -31.402,-6.887 -30.747,-5.4C-29.912,-3.547 -26.59,-2.56 -24.343,-2.165C-18.426,-1.125 -14.027,-1.062 -8.241,-1.93C-3.491,-2.641 0.767,-4.503 2.61,-7.094C3.67,-8.583 3.715,-10.764 2.633,-11.821C0.585,-13.822 -2.591,-14.407 -5.318,-13.916C-7.731,-13.481 -11.013,-13.574 -10.355,-16.564C-10.283,-16.89 -10.147,-17.196 -9.932,-17.453C-9.158,-18.382 -6.721,-20.387 0,-19.867"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2753.96,631.247)">
                        <path
                            d="M0,22.242C-0.961,20.243 -0.515,17.01 1.05,15.273C3.278,12.8 6.152,12.648 8.904,12.31C15.496,11.5 21.036,10.296 22.874,6.604C24.394,3.548 20.71,1.522 17.651,2.602C14.32,3.778 11.7,6.373 8.704,8.271C5.656,10.2 2.789,10.2 0.498,7.55C-1.437,5.311 -0.504,1.101 1.387,-0.259C4.288,-2.346 10.283,-1.956 14.202,-1.886C20.971,-1.765 48.149,-1.777 54.022,-1.659C56.487,-1.609 56.943,0.97 56.921,3.375C56.859,10.297 49.818,8.12 42.802,8.259C37.331,8.367 31.27,9.716 26.263,12.297C23.798,13.715 21.722,16.594 23.714,16.594C31.589,16.594 48.166,16.531 53.5,16.334C55.305,16.267 56.565,17.047 56.826,18.672C57.376,22.101 55.995,24.588 51.716,24.134C40.162,22.908 30.876,22.356 20.406,23.206C15.337,23.617 10.54,24.012 5.773,24.298C5.773,24.298 1.104,24.539 0,22.242"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2779.65,619.845)">
                        <path
                            d="M0,-15.298C2.803,-15.103 4.667,-14.929 7.772,-14.518C10.287,-14.185 13.551,-14.106 14.838,-12.343C16.657,-9.851 13.68,-8.559 10.311,-7.524C8.507,-6.97 6.99,-6.488 5.527,-6.198C4.104,-5.916 3.063,-5.959 1.621,-4.56C0.102,-3.086 -0.496,-1.045 -3.383,-0.747C-8.724,-0.195 -17.636,-1.882 -16.963,-6.748C-15.874,-14.626 -7.684,-15.579 -3.195,-15.471C-2.395,-15.452 -0.798,-15.354 0,-15.298M-13.447,-20.693C-18.518,-20.13 -23.501,-19.046 -25.508,-13.695C-27.006,-9.701 -26.353,-4.921 -25.321,-2.709C-24.108,-0.109 -21.994,1.549 -19.17,2.621C-10.947,5.741 2.766,5.34 12.766,3.691C19.646,2.556 30.094,0.556 31.015,-4.832C31.318,-6.609 31.405,-10.119 30.755,-12.026C30.217,-13.604 29.013,-14.601 27.851,-15.258C23.908,-17.486 17.384,-19.231 11.842,-20.013C5.49,-20.91 1.157,-21.039 -6.687,-21.039C-8.421,-21.039 -11.677,-20.89 -13.447,-20.693"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2790.55,548.104)">
                        <path
                            d="M0,12.945C0.323,14.094 -0.643,14.945 -1.573,13.78C-3.242,11.689 -3.968,9.793 -4.488,6.759C-4.683,5.618 -4.551,4.539 -4.509,4.278C-4.239,2.567 -2.614,2.794 -2.386,3.856M-16.489,12.978C-18.493,12.613 -20.507,11.879 -21.356,11.331C-23.436,9.988 -25.1,8.47 -22.054,6.993C-18.756,5.394 -15.965,5.018 -12.854,4.65C-10.176,4.333 -8.992,4.138 -8.285,6.02C-5.154,14.36 -6.717,14.43 -13.859,13.455M-19.081,-4.833C-25.883,-3.577 -29.607,-2.065 -33.328,0.41C-36.748,2.686 -37.225,4.593 -37.312,7.41C-37.383,9.724 -36.252,12.642 -30.955,14.495C-14.679,20.186 -0.727,20.432 15.862,20.851C17.7,20.898 20.101,20.56 20.17,19.078C20.281,16.684 19.501,15.73 15.706,15.724C12.891,15.719 9.853,16.253 7.566,15.002C4.474,13.288 2.902,9.01 3.006,5.926C3.077,3.813 4.854,3.234 6.867,4.163C9.878,5.518 11.296,7.985 15.842,7.903C16.799,7.886 18.888,7.626 19.585,6.337C20.411,4.81 20.17,2.372 20.17,-1.041C20.17,-3.706 19.753,-4.916 17.147,-5.266C8.17,-6.472 -2.18,-7.953 -19.081,-4.833"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2790.55,489.948)">
                        <path
                            d="M0,12.945C0.323,14.094 -0.643,14.945 -1.573,13.78C-3.242,11.689 -3.968,9.793 -4.488,6.759C-4.683,5.618 -4.551,4.539 -4.509,4.278C-4.239,2.567 -2.614,2.794 -2.386,3.856M-16.489,12.978C-18.493,12.613 -20.507,11.879 -21.356,11.331C-23.436,9.988 -25.1,8.47 -22.054,6.993C-18.756,5.394 -15.965,5.018 -12.854,4.65C-10.176,4.333 -8.992,4.138 -8.285,6.02C-5.154,14.36 -6.717,14.43 -13.859,13.455M-19.081,-4.833C-25.883,-3.577 -29.607,-2.065 -33.328,0.41C-36.748,2.686 -37.225,4.593 -37.312,7.41C-37.383,9.724 -36.252,12.642 -30.955,14.495C-14.679,20.186 -0.727,20.432 15.862,20.851C17.7,20.898 20.101,20.56 20.17,19.078C20.281,16.684 19.501,15.73 15.706,15.724C12.891,15.719 9.853,16.253 7.566,15.002C4.474,13.288 2.902,9.01 3.006,5.926C3.077,3.813 4.854,3.234 6.867,4.163C9.878,5.518 11.296,7.985 15.842,7.903C16.799,7.886 18.888,7.626 19.585,6.337C20.411,4.81 20.17,2.372 20.17,-1.041C20.17,-3.706 19.753,-4.916 17.147,-5.266C8.17,-6.472 -2.18,-7.953 -19.081,-4.833"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2754.74,534.201)">
                        <path
                            d="M0,-18.491C1.527,-24.983 10.422,-22.543 13.7,-19.356C16.392,-16.74 16.562,-11.612 12.487,-13.085C11.678,-13.377 10.582,-13.692 9.412,-13.985C5.042,-15.077 1.929,-7.169 4.162,-3.58C5.776,-1.318 8.209,-1.689 10.225,-3.097C12.272,-4.527 13.962,-6.347 15.335,-7.896C19.391,-12.474 23.646,-17.267 29.389,-19.792C33.333,-21.525 37.62,-21.649 41.523,-21.395C48.847,-20.918 52.457,-20.073 54.567,-17.235C55.787,-15.594 56.038,-11.297 56,-8.264C55.902,-0.464 54.667,3.372 48.997,4.677C43.703,5.897 38.005,5.235 32.665,3.655C31.123,3.199 29.36,2.379 28.427,1.039C26.039,-3.401 34.472,-7.272 37.887,-8.518C40.757,-9.564 45.155,-11.709 45.957,-9.499C46.595,-7.741 44.66,-6.787 42.728,-6.01C40.843,-5.252 39.833,-3.283 41.958,-1.973C44.553,-0.341 48.789,0.198 50.853,-2.369C53.397,-5.534 51.699,-11.085 48.516,-13.365C44.611,-16.162 37.467,-15.842 33.42,-12.453C30.863,-10.312 29.143,-8.242 26.085,-5.349C23.025,-2.453 21.016,-0.808 16.989,1.136C11.752,3.664 2.08,6.492 -0.098,0.674C-0.098,0.674 -3.185,-4.949 0,-18.491"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2772.77,693.866)">
                        <path
                            d="M0,10.246C-0.977,12.232 -9.18,11.719 -10.573,10.641C-13.478,8.391 -7.83,0.314 -4.28,1.147C-3.291,1.379 -2.789,2.1 -2.482,2.574C-0.699,5.328 1.015,8.359 0,10.252L0,10.246ZM10.179,4.087C8.629,1.621 6.845,-1.368 5.43,-3.851C4.7,-5.131 3.522,-5.999 2.332,-5.281C1.075,-4.523 1.423,-3.065 1.932,-2.16C3.458,0.548 4.339,1.992 5.734,4.513C6.86,6.55 7.229,7.611 6.124,8.175C4.898,8.8 3.87,7.221 1.367,3.013C-0.573,-0.25 -0.767,-0.731 -3.347,-5.145C-5.72,-9.203 -13.984,-9.289 -16.931,-4.696C-19.646,-0.462 -19.825,4.638 -18.519,9.522C-16.649,16.517 -9.828,17.973 -3.852,18.762C1.292,19.442 4.083,19.535 8.714,19.484C14.363,19.393 19.094,19.211 25.264,18.044C30.933,16.972 37.939,14.726 37.939,9.258L37.939,1.608C37.939,-0.131 35.816,-1.207 34.638,-2.009C28.202,-6.39 16.221,-7.599 12.505,-5.141C11.315,-4.354 11.345,-2.118 12.559,-1.467C15.468,0.091 19.42,-1.877 22.594,-0.569C25.018,0.297 26.643,3.69 25.169,6.268C23.623,8.973 19.232,9.843 15.657,8.521C15.657,8.521 12.299,7.46 10.179,4.087"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                    <g transform="matrix(1,0,0,1,2759.42,714.351)">
                        <path
                            d="M0,33.536C-3.28,33.191 -6.096,32.059 -6.045,28.763C-5.948,22.489 -6.045,21.808 -6.045,16.592L-6.045,5.812C-6.045,0.32 3.304,-0.761 7.618,0.266C12.431,1.413 18.109,4.514 17.038,6.516C16.814,6.935 16.404,7.656 15.115,7.749C11.484,8.011 6.974,4.728 3.804,7.838C2.881,8.742 1.834,10.755 3.223,11.774C4.497,12.708 7.857,11.602 9.578,11.47C13.274,11.184 16.179,11.154 19.893,11.491C29.501,12.363 39.502,12.648 49.06,11.221C50.626,11.131 51.257,11.508 51.293,12.98C51.337,14.752 51.363,18.641 51.367,20.675C51.37,22.292 51.216,22.785 50.418,23.531C48.543,25.072 45.333,24.944 42.99,24.772C38.469,24.441 33.869,23.059 28.948,21.945C26.206,21.325 9.872,16.78 5.559,19.985C4.538,20.744 3.703,21.859 4.436,23.705C5.102,25.38 7.315,25.536 8.896,24.989C11.035,24.37 12.863,21.981 14.979,22.722C17.767,23.697 16.336,26.504 14.952,28.067C14.739,28.307 14.531,28.546 14.294,28.764C12.791,30.144 7.232,34.297 0,33.536"
                            style="fill:rgb(0,152,74);fill-rule:nonzero;" />
                    </g>
                </g>
                <g transform="matrix(-7.65404e-16,4.16667,-4.16667,-7.65404e-16,-97.8417,3615.81)">
                    <path
                        d="M1201.91,-904.485C1218.26,-904.485 1231.51,-891.233 1231.51,-874.886L1231.51,-28.25C1231.51,-11.903 1218.26,1.349 1201.91,1.349L626.689,1.349L626.912,-904.485L1201.91,-904.485ZM629.608,-901.787C629.608,-901.787 629.396,-40.122 629.387,-1.349L1201.91,-1.349C1216.77,-1.349 1228.81,-13.393 1228.81,-28.25L1228.81,-874.886C1228.81,-889.743 1216.77,-901.787 1201.91,-901.787L629.608,-901.787Z"
                        style="fill:rgb(35,31,32);" />
                </g>
                <g transform="matrix(2.16836,0,0,0.0681818,1052.43,7855.26)">
                    <rect x="-530.48" y="7651.09" width="1735.45" height="349.206" />
                </g>
            </g>
        </svg>
        <div id="cartellina-indirizzo"
            class="content-indirizzo w-[95%] text-[2em] text-center text-[#00984A] absolute bottom-[8.8%] left-[50%] translate-x-[-50%]">
            {{ props.indirizzo }} - <span class="icona font-['iamtelefonoregular']">¡</span> {{ props.telefono }}
        </div>
    </div>
</template>