<script setup>
import { ref } from 'vue';
import { useForm } from "@inertiajs/vue3";
import CartellinaTecnocasa from '../../Components/Anteprime/CartellinaTecnocasa.vue';
import Termini from '../../Components/Termini.vue';

const props = defineProps({
    blocks: Object,
    auth:Object,
});

const blocks = JSON.parse(props.blocks.blocks);
console.log(blocks);

//const bloccoperpezzo = {
//    nCartelline: blocks.per_cartelline,
//    nSottobicchieri: blocks.per_sottobicchieri,
//    nPortachivi: blocks.per_portachiavi,
//};

const form = useForm({
    stampa_indirizzo: '',
    stampa_telefono: '',
    
    //block_id: blocks.id,
    n_blocks: blocks.min_blocks,

    nCartelline: blocks.min_blocks * blocks.per_cartelline,
    nSottobicchieri: blocks.min_blocks * blocks.per_sottobicchieri,
    nPortachivi: blocks.min_blocks * blocks.per_portachiavi,
    
    f_ragione_sociale: '',
    f_p_iva: '',
    f_codice_fiscale: '',

    f_cap: '',
    f_comune: '',
    f_indirizzo: '',
    f_provincia: '',
    
    f_codice_destinatario: '',
    f_pec: '',
    
    s_destinatario: '',
    s_cap: '',
    s_comune: '',
    s_indirizzo: '',
    s_provincia: '',

    ic_referente: props.auth.user.name + ' ' + props.auth.user.surname,
    ic_telefono: props.auth.user.phone,
    ic_email: props.auth.user.email,
});

const totalPrice = ref(0);

const setTotalPrize = () => {
    totalPrice.value = (form.nCartelline * blocks.price_cartellina) + 
                        (form.nSottobicchieri * blocks.price_sottobicchieri) +
                        (form.nPortachivi * blocks.price_portachiavi);
}

const formErrors = ref({});

const currentStep = ref(1);

const valStringEmpty = (val) => {
    return val != null && val != '';
}

const validateStep1 = () => {
    if (!valStringEmpty(form.stampa_indirizzo)) { formErrors.value = {...formErrors.value, 'stampa_indirizzo': ['L\'indirizzo della cartellina non può essere vuoto']}; }
    if (!valStringEmpty(form.stampa_telefono)) { formErrors.value = {...formErrors.value, 'stampa_telefono': ['Il telefono della cartellina non può essere vuoto']}; }
    if (Object.keys(formErrors.value).length > 0) { return false; }
    return true;
}

const validateStep2 = () => {
    return true;
}

const validateStep3 = () => {
    if (!valStringEmpty(form.f_ragione_sociale)) { formErrors.value = {...formErrors.value, 'f_ragione_sociale': ['Il nome della ragione sociale non può essere vuoto']}; }
    if (!valStringEmpty(form.f_p_iva)) { formErrors.value = {...formErrors.value, 'f_p_iva': ['La partita iva non può essere vuoto']}; }
    if (!valStringEmpty(form.f_codice_destinatario)) { formErrors.value = {...formErrors.value, 'f_codice_destinatario': ['Il codice destinatario non può essere vuoto']}; }
    if (!valStringEmpty(form.f_cap)) { formErrors.value = {...formErrors.value, 'f_cap': ['Il CAP non può essere vuoto']}; }
    if (!valStringEmpty(form.f_comune)) { formErrors.value = {...formErrors.value, 'f_comune': ['Il comune non può essere vuoto']}; }
    if (!valStringEmpty(form.f_indirizzo)) { formErrors.value = {...formErrors.value, 'f_indirizzo': ['L\'indirizzo non può essere vuoto']}; }
    if (!valStringEmpty(form.f_provincia)) { formErrors.value = {...formErrors.value, 'f_provincia': ['La provincia non può essere vuota']}; } 
    if (!form.termini) { formErrors.value = {...formErrors.value, 'termini': ['Devi accettare i termini e le condizioni']}; }
    if (Object.keys(formErrors.value).length > 0) { return false; }
    return true;
}

const changeStep = (step) => {
    formErrors.value = {};
    switch (step) {
        case 2:
            if (!validateStep1()) return;
            setTotalPrize();
            break;
        case 3:
            if (!validateStep1()) return;
            if (!validateStep2()) return;
            break;
        case 4:
            if (!validateStep1()) return;
            if (!validateStep2()) return;
            if (!validateStep3()) return;
            break;
    }
    currentStep.value = step;
}

const onChangeBlocchi = (e) => {
    form.nCartelline = e.target.value * blocks.per_cartelline;
    form.nSottobicchieri = e.target.value * blocks.per_sottobicchieri;
    form.nPortachivi = e.target.value * blocks.per_portachiavi;
    setTotalPrize();
}




const saveOrder = () => {
    form.post('/order', {
        onError: (errors) => { console.log(errors) },
    })
}


</script>
<template>
    <h1 class="text-xl my-2">Ordine:</h1>
    <div class="stepper flex flex-row">
        <div class="basis-1/4 relative flex items-center">
            <span @click="changeStep(1)" class="cursor-pointer text-center px-4 py-1 rounded-full inline-block bg-[#3C3C3D] text-white z-10 flex-shrink-0">1</span>
            <span class="step-linea block h-1 bg-[#3C3C3D] flex-grow"></span>
        </div>
        <div class="basis-1/4 relative flex items-center">
            <span @click="changeStep(2)" 
                :class="(currentStep == 1) ? 'bg-[#CACACA] text-black' : 'bg-[#3C3C3D] text-white'" 
                class="cursor-pointer text-center px-4 py-1 rounded-full inline-block z-10 flex-shrink-0">2</span>
            <span 
                :class="(currentStep == 1) ? 'bg-[#CACACA]' : 'bg-[#3C3C3D]'" 
                class="step-linea block h-1 flex-grow"></span>
        </div>
        <div class="basis-1/4 relative flex items-center">
            <span @click="changeStep(3)"
                :class="(currentStep <  3) ? 'bg-[#CACACA] text-black' : 'bg-[#3C3C3D] text-white'" 
                class="cursor-pointer text-center px-4 py-1 rounded-full inline-block z-10 flex-shrink-0">3</span>
            <span 
                :class="(currentStep < 3) ? 'bg-[#CACACA]' : 'bg-[#3C3C3D]'" 
                class="step-linea block h-1 flex-grow"></span>
        </div>
        <div class="basis-1/4 relative flex items-center">
            <span 
                :class="(currentStep < 3) ? 'bg-[#CACACA]' : 'bg-[#3C3C3D]'" 
                class="step-linea block h-1 flex-grow"></span>
            <span @click="changeStep(4)"
                :class="(currentStep <  4) ? 'bg-[#CACACA] text-black' : 'bg-[#3C3C3D] text-white'" 
                class="cursor-pointer text-center px-4 py-1 rounded-full inline-block z-10 flex-shrink-0 ml-auto">Riepilogo</span>            
        </div>
    </div>

    <!-- STEP 1 -->
    <div v-if="currentStep == 1" class="step-1 my-3">
        <h2>Inserisci i dati per la stampa</h2>

        <p class="text-xs text-slate-600">Qui sotto puoi vedere l'anteprime della cartellina</p>
    </div>

    <!-- STEP 2 -->
    <div v-if="currentStep == 2" class="step-2 my-3">
        <h2>Inserisci il Numero di blocchi che vuoi ordinare (il minimo numero di blocchi ordinabili è 4)</h2>
        <div class="flex flex-row justify-between  items-center py-3">
            <div class="pr-2">
                <label>N. Blocchi:</label>
                <input @change="onChangeBlocchi" type="number" :min="blocks.min_blocks"  v-model="form.n_blocks">
            </div>
            <div class="pr-2">
                <label>Cartelline:</label>
                <input type="number" v-model="form.nCartelline" disabled>
            </div>
            <div class="pr-2">
                <label>Sottobicchieri:</label>
                <input type="number" v-model="form.nSottobicchieri" disabled>
            </div>
            <div class="pr-2">
                <label>Portachiavi:</label>
                <input type="number" v-model="form.nPortachivi" disabled>
            </div>
            <div class="pl-2 text-right">
                <a @click="changeStep(3)" class="primary-btn rounded-xl w-auto p-4 cursor-pointer">CONTINUA</a>
            </div>
        </div>
        <p>Totale: € <span>{{ totalPrice }}</span> + IVA</p>
    </div>

    <!-- STEP 3 -->
    <div v-if="currentStep == 3" class="step-3 my-3">
        <h2>Inserisci i dati per la fattura:</h2>

        <div class="flex flex-row py-3">
            <div class="basis-1/4 pr-2">
                <label>Ragione sociale:</label>
                <input type="text" v-model="form.f_ragione_sociale">
                <p v-if="formErrors.f_ragione_sociale" class="text-red-500">{{ formErrors.f_ragione_sociale[0] }}</p>
            </div>
            <div class="basis-1/4 pr-2">
                <label>P.IVA:</label>
                <input type="text" v-model="form.f_p_iva">
                <p v-if="formErrors.f_p_iva" class="text-red-500">{{ formErrors.f_p_iva[0] }}</p>
            </div>
            <div class="basis-1/4 pr-2">
                <label>Codece Fiscale:</label>
                <input type="text" v-model="form.f_codice_fiscale">
            </div>
        </div>
        <div class="flex flex-row py-3">
            <div class="basis-1/4 pr-2">
                <label>CAP:</label>
                <input type="text" v-model="form.f_cap">
                <p v-if="formErrors.f_cap" class="text-red-500">{{ formErrors.f_cap[0] }}</p>
            </div>

            <div class="basis-1/4 pr-2">
                <label>Comune:</label>
                <input type="text" v-model="form.f_comune">
                <p v-if="formErrors.f_comune" class="text-red-500">{{ formErrors.f_comune[0] }}</p>
            </div>

            <div class="basis-1/4 pr-2">
                <label>Indirizzo:</label>
                <input type="text" v-model="form.f_indirizzo">
                <p v-if="formErrors.f_indirizzo" class="text-red-500">{{ formErrors.f_indirizzo[0] }}</p>
            </div>

            <div class="basis-1/4 pr-2">
                <label>Provincia:</label>
                <input type="text" v-model="form.f_provincia">
                <p v-if="formErrors.f_provincia" class="text-red-500">{{ formErrors.f_provincia[0] }}</p>
            </div>
        </div>
        <div class="flex flex-row py-3 pb-5">
            <div class="basis-1/4 pr-2">
                <label>Codice destinatario:</label>
                <input type="text" v-model="form.f_codice_destinatario">
                <p v-if="formErrors.f_codice_destinatario" class="text-red-500">{{ formErrors.f_codice_destinatario[0] }}</p>
            </div>

            <div class="basis-1/4 pr-2">
                <label>Pec:</label>
                <input type="text" v-model="form.f_pec">
            </div>
        </div>
        
        <h2>Inserisci i dati per la spedizione, se diversi da quelli di fatturazione:</h2>

        <div class="flex flex-row  items-center py-3">
            <div class="basis-1/3 pr-2">
                <label>Destinatario:</label>
                <input type="text" v-model="form.s_destinatario">
            </div>

            <div class="basis-1/3 pr-2">
                <label>CAP:</label>
                <input type="text" v-model="form.s_cap">
            </div>

            <div class="basis-1/3 pr-2">
                <label>Comune:</label>
                <input type="text" v-model="form.s_comune">
            </div>

            <div class="basis-1/3 pr-2">
                <label>Indirizzo:</label>
                <input type="text" v-model="form.s_indirizzo">
            </div>

            <div class="basis-1/3 pr-2">
                <label>Provincia:</label>
                <input type="text" v-model="form.s_provincia">
            </div>


        </div>

        <h2>Inserisci i dati del referente</h2>

        <div class="flex flex-row  items-center py-3">
            <div class="basis-1/3 pr-2">
                <label>Referente:</label>
                <input type="text" v-model="form.ic_referente">
            </div>

            <div class="basis-1/3 pr-2">
                <label>Email:</label>
                <input type="text" v-model="form.ic_email">
            </div>

            <div class="basis-1/3 pr-2">
                <label>Telefono:</label>
                <input type="text" v-model="form.ic_telefono">
            </div>
        </div>

        <div class="flex flex-row items-center py-3">
            <div class="basis-1/3 pr-2">
                <label class="inline mr-2">Accetta Termini e Condizioni:</label>
                <input type="checkbox" v-model="form.termini">
                <p class="text-red-500 text-sm" v-if="formErrors.termini">Devi accettare i Termini e Condizioni</p>
            </div>
        </div>
        
        <div class="h-[400px] overflow-y-auto items-center p-5 bg-white rounded-xl">
            <Termini />
        </div>


        
    </div>

    <div v-if="currentStep == 4" class="step-3 my-3">
        <div class="flex flex-row items-center py-3">
            <div class="basis-1/2 pr-2">
            <a 
                @click="changeStep(1)" 
                class="primary-btn block mt-5 w-full cursor-pointer text-center py-2 rounded-xl">
                MODIFICA ORDINE
            </a>
            </div>

            <div class="basis-1/2 pl-2">
            <a 
                @click="saveOrder" 
                class="primary-btn block mt-5 w-full cursor-pointer text-center py-2 rounded-xl">
                CONFERMA ORDINE
            </a>
            </div>
        </div>
    </div>

    <div v-if="currentStep == 2" class="step-4 my-3">
        <img class="rounded-xl" :src="'/storage/showcase/tecnocasa_insieme.webp'" alt="tecnocasa">
    </div>

    <div v-if="currentStep == 4" class="step-4 my-3">
        <h2 class="pb-3 text-2xl">Resoconto</h2>
        <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700">
        <h3>Stampa Gadgets</h3>
        <div class="flex flex-row items-center py-3">
            <div class="basis-1/3 pr-2">
                <label>Indirizzo per stampa gadgets:</label>
                <p>{{ form.stampa_indirizzo }}</p>
            </div>
            <div class="basis-1/3 pr-2">
                <label>Telefono per stampa gadgets:</label>
                <p>{{ form.stampa_telefono }}</p>
            </div>
        </div>
        <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700">
        <h3>Dati fatturazione</h3>
        <div class="flex flex-row items-center py-3">
            <div class="basis-1/4 pr-2">
                <label>Ragione Sociale:</label>
                <p>{{ form.f_ragione_sociale }}</p>
            </div>
            <div class="basis-1/4 pr-2">
                <label>Partita IVA:</label>
                <p>{{ form.f_p_iva }}</p>
            </div>
            <div v-if="form.f_codice_fiscale" class="basis-1/4 pr-2">
                <label>Codice Fiscale:</label>
                <p>{{ form.f_codice_fiscale }}</p>
            </div>
        </div>
        <div class="flex flex-row items-center py-3">
            <div class="basis-1/4 pr-2">
                <label>CAP:</label>
                <p>{{ form.f_cap }}</p>
            </div>
            <div class="basis-1/4 pr-2">
                <label>Comune:</label>
                <p>{{ form.f_comune }}</p>
            </div>
            <div class="basis-1/4 pr-2">
                <label>Indirizzo:</label>
                <p>{{ form.f_indirizzo }}</p>
            </div>
            <div class="basis-1/4 pr-2">
                <label>Provincia:</label>
                <p>{{ form.f_provincia }}</p>
            </div>
        </div>
        <div class="flex flex-row items-center py-3">
            <div class="basis-1/4 pr-2">
                <label>Codice destinatario:</label>
                <p>{{ form.f_codice_destinatario }}</p>
            </div>
            <div v-if="form.f_pec" class="basis-1/4 pr-2">
                <label>PEC:</label>
                <p>{{ form.f_pec }}</p>
            </div>
        </div>
        <hr v-if="form.s_destinatario" class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700">
        
        <h3 v-if="form.s_destinatario">Indirizzo spedizione</h3>
        
        <div v-if="form.s_destinatario" class="flex flex-row items-center py-3">
            <div class="basis-1/5 pr-2">
                <label>Destinatario:</label>
                <p>{{ form.s_destinatario }}</p>
            </div>
            <div class="basis-1/5 pr-2">
                <label>CAP:</label>
                <p>{{ form.s_cap }}</p>
            </div>
            <div class="basis-1/5 pr-2">
                <label>Comune:</label>
                <p>{{ form.s_comune }}</p>
            </div>
            <div class="basis-1/5 pr-2">
                <label>Indirizzo:</label>
                <p>{{ form.SInidirizzo }}</p>
            </div>
            <div class="basis-1/5 pr-2">
                <label>Provincia:</label>
                <p>{{ form.s_provincia }}</p>
            </div>
        </div>
        <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-green-700">
        <h3>Gadgets</h3>
        <div class="flex flex-row items-center py-3">
            <div class="basis-1/5 pr-2">
                <label>Blocchi:</label>
                <p>{{ form.n_blocks }}</p>
            </div>
            <div class="basis-1/5 pr-2">
                <label>Cartelline:</label>
                <p>{{ form.nCartelline }}</p>
            </div>
            <div class="basis-1/5 pr-2">
                <label>Sottobicchieri:</label>
                <p>{{ form.nSottobicchieri }}</p>
            </div>
            <div class="basis-1/5 pr-2">
                <label>Portachiavi:</label>
                <p>{{ form.nPortachivi }}</p>
            </div>
            <div class="basis-1/5 pr-2">
                <label>Totale:</label>
                <p>€ {{ totalPrice }} + IVA</p>
            </div>
        </div>
        <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-green-700">
        <h3>Anteprima Cartellina</h3>
    </div>

    <div v-if="currentStep == 1 || currentStep == 4" class="anteprima bg-[#3C3C3D] p-5 rounded-xl">
        <div class="flex flex-row flex-wrap">
            <div class="basis-1/3 p-5 bg-white">
                <div class="">
                        <label>Indirizzo:</label>
                        <input type="text" v-model="form.stampa_indirizzo">
                        <p v-if="formErrors.stampa_indirizzo" class="text-red-500">{{ formErrors.stampa_indirizzo[0] }}</p>
                </div>
                <div class="">
                        <label>Telefono:</label>
                        <input type="text"  v-model="form.stampa_telefono">
                        <p v-if="formErrors.stampa_telefono" class="text-red-500">{{ formErrors.stampa_telefono[0] }}</p>
                </div>
                <div v-if="currentStep == 1" class="">
                    <a @click="changeStep(2)" class="primary-btn mt-5">CONTINUA</a>
                </div>
                

            </div>
            <div class=" basis-2/3 svg-anteprima w-full bg-white aspect-[1371/914] p-5">
                <CartellinaTecnocasa :indirizzo="form.stampa_indirizzo" :telefono="form.stampa_telefono" />
            </div>
        </div>
    </div>



    <div v-if="currentStep == 3" class="step-3 my-3">
        <a 
            @click="changeStep(4)" 
            class="primary-btn block mt-5 w-full cursor-pointer text-center py-2 rounded-xl">
            CONTINUA
        </a>
    </div>

    <div v-if="currentStep == 4" class="step-3 my-3">
        <div class="flex flex-row items-center py-3">
            <div class="basis-1/2 pr-2">
            <a 
                @click="changeStep(1)" 
                class="primary-btn block mt-5 w-full cursor-pointer text-center py-2 rounded-xl">
                MODIFICA ORDINE
            </a>
            </div>

            <div class="basis-1/2 pl-2">
            <a 
                @click="saveOrder" 
                class="primary-btn block mt-5 w-full cursor-pointer text-center py-2 rounded-xl">
                CONFERMA ORDINE
            </a>
            </div>
        </div>
    </div>

</template>