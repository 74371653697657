<script setup>
import { useForm } from "@inertiajs/vue3";
import TextInput from "../../Components/TextInput.vue";

const form = useForm({
    email: null,
    password: null,
    remember: null,
});


const handleSubmit = () => {
    form.post('/login', {
        onError: () => form.reset('password'),
    });
}
</script>
<template>
    <Head title="Home" />
    <h1 class="title">Accedi al tuo account</h1>
    <div class="w-2/4 mx-auto">
        <form @submit.prevent="handleSubmit">
            <TextInput v-model="form.email" name="email" type="email" :message="form.errors.email" />
            <TextInput v-model="form.password" name="password" type="password" :message="form.errors.password" />
            <div class="flex items-center gap-2">
                <input type="checkbox" v-model="form.remember" />
                <label>Ricordami</label>
            </div>
            <div class="text-slate-500 my-2">
                <button class="primary-btn" :disabled="form.processing">Login</button>
            </div>
        </form>
    </div>
    <hr class="my-8" />
    <div class="w-2/4 mx-auto">
        <p class="mb-2">Non hai un account?</p>
        <a href="/register" class="primary-btn">CLICCA QUI PER REGISTRARTI</a>
    </div>
</template>