<script setup>

const model = defineModel({
    type: null,
    required: true,
});

defineProps({
    name: {
        type: String,
        required: true
    },
    type: {
        type: String,
        default: 'text'
    },
    message: {
        type: String,
        default: null
    },
    required: {
        type: Boolean,
        default: false
    },
    required_alt: {
        type: Boolean,
        default: false
    },
    basisDivide: {
        type: Number,
        default: 4
    }
})
</script>
<template>

    <div :class="'h-full p-2'+ ' lg:basis-1/' + basisDivide">
        <label>{{ name }}<span v-if="required">*</span><span v-if="required_alt">**</span> :</label>
        <input v-if="type !== 'select'" :type="type" v-model="model" :required="required" :class="{'!ring-red-500' : message}"  />
        <small class="error" v-if="message">{{ message }}</small>
    </div>
</template>