import './bootstrap';

import { createApp, h } from 'vue'
import { createInertiaApp, Head, Link } from '@inertiajs/vue3'
import { ZiggyVue } from '../../vendor/tightenco/ziggy'
import Layout from './Layouts/Layout.vue'

createInertiaApp({
  title: (title) => `Prodotti Alfa Finiti ${ title ? ' | '+title : ''}`,
  resolve: name => {
    const pages = import.meta.glob('./Pages/**/*.vue', { eager: true })
    let page = pages[`./Pages/${name}.vue`]
    page.default.layout = page.default.layout || Layout
    return page
  },
  progress: {
      delay: 250,
      color: '#fff',
      includeCSS: true,
      showSpinner: true,
    },
  
  setup({ el, App, props, plugin }) {
    const application = createApp({ render: () => h(App, props) })
      .use(plugin)
      .use(ZiggyVue)
      .component('Head', Head)
      .component('Link', Link)
      .mount(el);
    delete el.dataset.page
    return application;
  },
})