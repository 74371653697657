<script setup>
import { onBeforeMount, onMounted, ref, watch } from 'vue';
import { useForm } from "@inertiajs/vue3";

import TextInputFlex from "../../Components/TextInputFlex.vue";
import SelectInputFlex from "../../Components/SelectInputFlex.vue";

const form = useForm({
    // dati refetente
    referente_nome: null,
    referente_cognome: null,
    referente_email: null,
    referente_telefono: null,
    
    // dati aziendali
    azienda_tipo: null,
    azienda_tipo_string: null
    
});

const props = defineProps({
    branchesType: Array
});

const branchesType = ref(null);
const brancheTypeZero = ref(false);

const addValtest = () => {
    form.referente_nome             = "nome"
    form.referente_cognome          = "cognome"
    form.referente_email            = "email@email.local"
    form.referente_telefono         = "telefono"
    
    // dati aziendali
    form.azienda_tipo               = null
    form.azienda_tipo_string        = null
    
}

const handleSubmit = () => {
    form.post('/user/dashboard/profile', {
        onSuccess: (data) => { console.log(data) },
        //onError: () => form.reset('passwrord', 'password_confirmation'),
    });
}


onBeforeMount(() => {
    console.log('tets');
    addValtest();
    
    branchesType.value = [
        { label: 'Scegli un tipo', value: null, selected: 'selected', disabled: true, hidden: true },
        ...props.branchesType.map((branchType) => ({ label: branchType.name, value: branchType.id })),
        { label: 'Altro', value: '0' },
    ];
});

watch(() => form.azienda_tipo, () => {
    if (form.azienda_tipo === '0'){ brancheTypeZero.value = true; return; }
    brancheTypeZero.value = false;
    form.azienda_tipo_string = null; 
});

</script>
<template>
    <h1>Info Profilo:</h1>

    <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700">
    <form  @submit.prevent="handleSubmit">
    <div class="my-3">
        <h2>Inserisci il tipo di azienda:</h2>
        <div class="flex flex-wrap">
            <SelectInputFlex 
                v-model:selected="form.azienda_tipo" 
                v-model:options="branchesType"
                name="Tipo" 
                :message="form.errors.azienda_tipo" 
                :required="true" />
            <TextInputFlex v-if="brancheTypeZero" v-model="form.azienda_tipo_string" name="Tipo Azienda" :message="form.errors.azienda_tipo_string" :required="true" />            
        </div>
    </div>
    <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700">
    <div class="my-3">
        <h2>Inserisci i dati del referente:</h2>
        <div class="flex flex-wrap">
            <TextInputFlex v-model="form.referente_nome" name="Nome" :message="form.errors.referente_nome" :required="true" />
            <TextInputFlex v-model="form.referente_cognome" name="Cognome" :message="form.errors.referente_cognome" :required="true" />
            <TextInputFlex v-model="form.referente_email" name="Email" type="email" :message="form.errors.referente_email" :required="true" />
            <TextInputFlex v-model="form.referente_telefono" name="Numero di Telefono" :message="form.errors.referente_telefono" :required="true" />
        </div>
    </div>
    
    <hr class="h-px my-2 bg-gray-200 border-0 dark:bg-gray-700">
    <button class="primary-btn" :disabled="form.processing">SALVA E CONTINUA</button>
    </form>


</template>