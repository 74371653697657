<script setup>
const selected = defineModel('selected');
const options = defineModel('options');

//const emit = defineEmits(['update:selected']);

defineProps({
    name: {
        type: String,
        required: true
    },
    required: {
        type: Boolean,
        default: false
    },
    message: {
        type: String,
        default: null
    },
    required_alt: {
        type: Boolean,
        default: false
    },
    basisDivide: {  
        type: Number,
        default: 4
    },
    cls: {
        type: String,
        default: ''
    }
});


</script>
<template>
    <div :class=" 'h-full' + ' ' + cls + ' lg:basis-1/' + basisDivide + ((basisDivide === 0) ? ' p-2 ' : ' '	)">
        <label>{{ name }}<span v-if="required">*</span><span v-if="required_alt">**</span> :</label>
        <select v-model="selected">
            <option 
                class="" 
                v-for="option in options" 
                :key="option.value" 
                :value="option.value" 
                :hidden="option.hidden" 
                :disabled="option.disabled">{{ option.label }}</option>
        </select>
        <small class="error" v-if="message">{{ message }}</small>
    </div>
</template>