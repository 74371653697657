<template>
    <div>
        <p>Termini e Condizioni di Vendita</p><br>

        <p>1. Definizioni</p><br>

        <p>1.1. "Azienda" si riferisce a Prodotti Alfa srl, con sede legale in Tromello (PV) - Via Garlasco, 29/31/33, P.IVA 00174390187.</p>
        <p>1.2. "Cliente" si riferisce alla persona fisica o giuridica che effettua un ordine presso l'Azienda.</p>
        <p>1.3. "Merce" si riferisce ai prodotti personalizzati ordinati dal Cliente.</p>
        <br>
        <p>2. Accettazione delle Condizioni</p>
        <br>
        <p>2.1. Effettuando un ordine, il Cliente accetta integralmente i presenti Termini e Condizioni di Vendita.</p>
        <p>2.2. Questi Termini e Condizioni sono pubblicati sul sito web dell'Azienda e possono essere modificati in qualsiasi momento senza preavviso.</p>
        <br>
        <p>3. Ordini</p>
        <br>
        <p>3.1. Gli ordini possono essere effettuati attraverso i canali indicati dall'Azienda (ad es. sito web, email, telefono).</p>
        <p>3.2. L'ordine si considera confermato solo dopo che l'Azienda ha inviato una conferma d'ordine al Cliente.</p>
        <br>
        <p>4. Fatturazione e Pagamento</p>
        <br>
        <p>4.1. Dopo la conferma dell'ordine, l'Azienda emetterà una fattura a nome del Cliente.</p>
        <p>4.2. La fattura sarà inviata tramite email all'indirizzo fornito dal Cliente al momento dell'ordine.</p>
        <p>4.3. Il pagamento della fattura deve essere effettuato entro 14 giorni dalla data di emissione.</p>
        <p>4.4. I metodi di pagamento accettati sono specificati nella fattura e possono includere bonifico bancario, carta di credito, PayPal, ecc.</p>
        <br>
        <p>5. Consegna della Merce</p>
        <br>
        <p>5.1. La Merce sarà prodotta e personalizzata solo dopo il ricevimento del pagamento completo da parte del Cliente.</p>
        <p>5.2. L'Azienda si impegna a consegnare la Merce entro 2 settimane dal ricevimento del pagamento.</p>
        <p>5.3. I tempi di consegna possono variare in base alla complessità della personalizzazione e alla destinazione della consegna. Eventuali ritardi saranno comunicati tempestivamente al Cliente.</p>
        <br>
        <p>6. Spedizione</p>
        <br>
        <p>6.1. La spedizione sarà effettuata all'indirizzo fornito dal Cliente al momento dell'ordine.</p>
        <p>6.2. Le spese di spedizione sono a carico del Cliente e saranno indicate nella fattura.</p>
        <p>6.3. L'Azienda non è responsabile per ritardi o danni causati dal corriere durante il trasporto.</p>
        <br>
        <p>7. Diritto di Recesso</p>
        <br>
        <p>7.1. Essendo la Merce personalizzata, il diritto di recesso non è applicabile, salvo diversa disposizione di legge.</p>
        <p>7.2. Eventuali reclami devono essere presentati entro 7 giorni dalla ricezione della Merce, corredati da documentazione fotografica e descrizione del problema.</p>
        <br>
        <p>8. Garanzia e Responsabilità</p>
        <br>
        <p>8.1. L'Azienda garantisce che la Merce corrisponde alle specifiche descritte nell'ordine.</p>
        <p>8.2. In caso di difetti comprovati, l'Azienda si impegna a sostituire la Merce difettosa senza costi aggiuntivi per il Cliente.</p>
        <p>8.3. L'Azienda non è responsabile per danni indiretti o conseguenti derivanti dall'uso della Merce.</p>
        <br>
        <p>9. Privacy</p>
        <br>
        <p>9.1. I dati personali del Cliente saranno trattati in conformità con la normativa vigente in materia di protezione dei dati personali (Regolamento UE 2016/679).</p>
        <p>9.2. Per ulteriori informazioni sulla gestione dei dati personali, il Cliente può consultare la Privacy Policy disponibile sul sito web dell'Azienda.</p>
        <br>
        <p>10. Legge Applicabile e Foro Competente</p>
        <br>
        <p>10.1. I presenti Termini e Condizioni sono regolati dalla legge italiana.</p>
        <br>
        <p>11. Contatti</p>
        <br>
        <p>11.1. Per qualsiasi informazione o assistenza, il Cliente può contattare l'Azienda ai seguenti recapiti:</p>
        <p>Email: info@prodottialfafiniti.it</p>
        <p>Telefono: 340.1767575</p>
        <br>
        <p>Data di ultima revisione: 01/06/2024</p>
    </div>
</template>